/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'components/elements/HeroDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)`
  p {
    text-align: justify;
    line-height: 30px;
  }

  h2 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.xxml};
    margin-bottom: 30px;

    @media screen and (max-width: 576px) {
      font-size: ${props => props.theme.font.size.xxl};
    }
  }

  td {
    border: 1px solid #101010;
    padding: 16px;
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout activePage={path}>
      <SEO yoast={yoast} path={path} />

      <HeroDefault fields={acf.banner} />

      <div className="container py-lg-5 py-3">
        <div className="row my-lg-5 my-3 py-lg-5 py-3">
          <div className="col-lg-6">
            <Image src={acf.story.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
          </div>
          <div className="col-lg-6">
            <Content content={acf.story.description} />
          </div>
        </div>
      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...innovationLabPageFrag
    }
  }
`

export default PageTemplate
